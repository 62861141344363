import React from 'react';
import { useState } from 'react';
import './coop.css';
import AbiEmployeesVideo from "../../assets/ABI_Employees_test.mp4"
import Coop1 from './Coop1';
import Coop2 from './Coop2';


const Coop = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  }


  return (
    <section className="services section" id="coop">
      <h2 className="section__title">Co-op Reflections</h2>
          <span className="section__subtitle">My Co-op work term reflections</span>
      <div className="services__container container grid">
      <Coop1/>
      <Coop2/>
      </div>
  </section>

  )
}

export default Coop
