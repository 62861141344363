import React from 'react';
import { useState } from 'react';
import './coop.css';
import AbiEmployeesVideo from "../../assets/ABI_Employees_test.mp4"


const Coop1 = () => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  }


  return (
    <section className="services section" id="coop1">
      <div className="services__content">
          <div>
          <i class='bx bx-bus services__icon'></i>
            <h3 className="services__title">Ministry of Transportation (MTO)- Co-op #2</h3>
          </div>
          <span className="services__button" onClick={() => toggleTab(6)}>View More<i className="uil uil-arrow-right services__button-icon"></i></span>
          <div className={toggleState === 6 ? "services__modal active-modal" : "services__modal"}>
            <div className="services__modal-content">
              <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

              <h3 className="services__modal-title">Role: Junior Technical Analyst/Developer Co-op</h3>
              <p className='services__modal-medium'>Employer:</p>
              <p className='services__modal-description'>The Ontario Ministry of Transportation (MTO) excels in integrating computing science into public services, utilizing big data and machine learning to optimize Ontario's transportation systems. Their work, characterized by innovative data ETL processes and a commitment to diversity, brings together a wide range of professionals to address the needs of a diverse Ontarian population. This approach not only impacts thousands daily but also sets a standard in technology use for public welfare and inclusive service delivery.</p>
              <p className='services__modal-medium'>Goals:</p>
              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon">
                </i>
                <p className="services__modal-info">Improve Power Automate Skills and knowledge on how to leverage it to automate tasks that require Microsoft apps.</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon">
                </i>
                <p className="services__modal-info">Improve Sharepoint web management skills and data management and privacy.</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon">
                </i>
                <p className="services__modal-info">Communicate complex technological tasks in a manner that is easy for clients/users to understand and give solid time.</p>
              </li>
              
              <p className='services__modal-medium'>How I Developed My Skills:</p>
              <p className='services__modal-description'>I enhanced my skills on the job by researching various technologies and actively engaging with my team. Learning from colleagues and applying their suggestions significantly contributed to my professional growth and technical capabilities, particularly through gaining practical insights into new technologies.</p>

              <p className='services__modal-medium'>Skills Learned:</p>
              <p className='services__modal-description'>I aimed to learn Power Automate, SharePoint, and some React, along with developing applications and enhancing communication skills. These skills are foundational in computing and will be highly beneficial in future roles, offering versatility and effectiveness in various technical and managerial positions.</p>
              <p className='services__modal-medium'>Technologies Used:</p>
              <p className="services__modal-description">I chose Power Automate, and SharePoint because they are essential for data handling and automation development, which were key aspects of my role at the Ministry of Transportation</p>
              <p className='services__modal-medium'>Completed Goals:</p>
              <p className="services__modal-description">I successfully enhanced Power Automate, and SharePoint skills, by developing functional automation work flows and SharePoint sites, and significantly improved oral communication skills.</p>
              <p className='services__modal-medium'>Conclusion:</p>
              <p className="services__modal-description"> As I conclude my co-op term at the Ontario Ministry of Transportation (MTO), I am grateful for the invaluable experience and the achievements in mastering key technologies like Power Automate and SharePoint. My success in these areas, especially in the Power Automate, was greatly enhanced by the support and guidance from my supervisor(s); Lionel, Cole & Jean, Chen, as well as my manager Kevin, Wang, & the team.
                A special thanks to Jean & Lionel for their insights and advocacy, and to Kevin for his leadership and support, which were crucial in my professional development. The collaborative and innovative environment fostered by my colleagues significantly enriched my skill set and contributed to my achievements.
                I move forward in my career with not only enhanced technical skills but also lessons in teamwork, diversity, and innovation, reflecting the culture at MTO.</p>
            </div>
          </div>
        </div>      
  </section>

  )
}

export default Coop1
